<!-- 部门 -->
<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<style type="text/css" scoped>
  /deep/ .el-form-item__content {
    line-height: inherit;
  }

  /deep/.el-form-item {
    margin-bottom: 0;
  }

  /deep/.el-pagination__jump {
    margin-left: 0;
    margin-right: 10px;
  }

  /deep/.el-select.blueBorder .el-input__inner {
    border-color: rgb(23, 118, 210)
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import BaseHeader from "@/components/base-header";
  import {
    getOrgDepartmentList,
    updateOrgDepartmentQyzt,
    deleteOrgDepartment,
    addOrgDepartment,
    editOrgDepartment,
    orgEasyList,
    orgUnitsList,
  } from "@/api/admin/base/org.js"


  /**
   * Dashboard component
   */
  export default {
    components: {
      Layout,
      BaseHeader,

    },
    data() {
      return {
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "部门信息",
            active: true
          }
        ],
        jgmc: "",
        dwmc: "",
        exportTitle: "部门信息导出",
        importTitle: "部门信息导入",
        checked: true,
        showmodal: false,
        tableList: [],
        hasName: false,
        orgList: [],
        orgUnitsList: [],
        dialogTitle: "添加部门",
        module: "JGBM",
        disabled: false,
        addForm: {},
        select: {},
        pageData: {
          pageNum: 1,
          pageSize: 20,
          total: 0
        }
      };
    },
    methods: {
      // 删除
      deleteItem(sid, text) {
        this.$confirm(`此操作将永久删除该【${text}】的数据, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteOrgDepartment(sid).then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: '数据删除成功!'
              });
            }
            this.getList()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      // 获取数据列表
      getList() {
        getOrgDepartmentList({
          ...this.pageData,
        }).then(res => {
          if (res.status) {
            this.tableList = res.data
            this.pageData.total = res.total
          }
        })
      },
      // 获取机构列表
      getOrgList() {
        orgEasyList().then(res => {
          if (res.status) {
            this.orgList = res.data
          }
        })
      },
      // 获取单位列表
      getOrgUnitsList(params) {
        orgUnitsList({
          jgdm: params
        }).then(res => {
          if (res.status) {
            this.orgUnitsList = res.data
          }
        })
      },
      // 改变启用状态
      changeQyzt(obj) {
        let {
          sid,
          qyzt
        } = obj
        updateOrgDepartmentQyzt({
          sid: sid,
          qyzt: qyzt
        }).then(res => {
          if (res.status) {
            this.$message({
              type: 'success',
              message: '状态修改成功！'
            })
          }
        })
      },
      // 添加类别
      addOrgDepartment() {
        let formDate = JSON.parse(JSON.stringify(this.addForm))
        addOrgDepartment(formDate).then(res => {
          if (res.status) {
            this.success()
          }

        })
      },
      editOrgDepartment() {
        let formDate = JSON.parse(JSON.stringify(this.addForm))
        formDate.sid = this.addForm.sid
        editOrgDepartment(formDate).then(res => {
          if (res.status) {
            this.success()
          }
        })
      },
      success() {
        this.$notify({
          type: 'success',
          message: '数据操作成功!',
          duration: 3000,
        })
        this.addForm = {
          jgdm: "",
          jgmc: "",
          dwdm: "",
          qyzt: true,
          dwmc: "",
          bmdm: "",
          bmmc: "",
          ssbmdm: "",
          fzrxm: "",
          fzrzh: "",
          bmjj: "",
        }
        this.showmodal = false;
        this.getList()
      },
      addItem() {
        this.showmodal = true
        this.dialogTitle = "添加部门"
        this.addForm = {
          jgdm: this.select.jgdm,
          jgmc: "",
          dwdm: this.select.dwdm,
          qyzt: true,
          dwmc: "",
          bmdm: "",
          bmmc: "",
          ssbmdm: "",
          fzrxm: "",
          fzrzh: "",
          bmjj: "",
        }
        this.getjgmc()
        this.getdwmc()
        this.getBm(this.module, 'bm').then(e => {
          this.addForm.bmdm = e
        })
      },
      editItem(data) {
        let copyData = JSON.parse(JSON.stringify(data))
        this.addForm = copyData
        this.showmodal = true
        this.dialogTitle = "修改部门"
        this.disabled = true
      },
      submit() {
        if (this.addForm.sid) {
          this.editOrgDepartment()
        } else {
          this.addOrgDepartment()
        }
      },
      handleSizeChange(val) {
        this.pageData.pageSize = val
        this.getList();
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val
        this.getList();
      },
      searchClick() {
        this.pageData.pageNum = 1
        this.getlistByParam()
      },
      // 条件查询
      getlistByParam() {
        getOrgDepartmentList({
          ...this.pageData,
          ...this.select
        }).then(res => {
          if (res.status) {
            this.tableList = res.data
            this.pageData.total = res.total;
          }
        })
      },
      getjgmc() {
        let val = this.addForm.jgdm
        let obj = this.orgList.find(k => {
          return k.jgdm === val
        })
        this.addForm.jgmc = obj.jgmc
        this.getOrgUnitsList(val)

      },
      // 改变单位
      changeDw(val) {
        this.getOrgUnitsList(val)
      },
      getdwmc() {
        let val = this.addForm.dwdm
        let obj = this.orgUnitsList.find(k => {
          return k.dwdm === val
        })
        this.addForm.dwmc = obj.dwmc
      },
      uploadSuccess() { // 数据导入之后刷新列表
        this.getList()
      }
    },
    mounted() {
      this.pageData.jgdm = this.$route.query.jgdm
      this.pageData.dwdm = this.$route.query.dwdm
      this.getList()
      this.getOrgList()
      this.getOrgUnitsList()
    }
  };
</script>

<template>
  <Layout>
    <BaseHeader :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <div class="pb-3 border-dash check-table-top">
              <div class="flexList" style="flex: 1;">
                <el-select class=" mr-2 blueBorder" clearable style="width:12%;" placeholder="请选择机构名称"
                  v-model="select.jgdm" size="small" @change="changeDw(select.jgdm)">
                  <el-option :value="item.jgdm" v-for="item in orgList" :key="item.value" :label="item.jgmc">
                    {{item.jgmc}}
                  </el-option>
                </el-select>
                <el-select class=" mr-2 blueBorder" clearable style="width: 12%;" placeholder="请选择单位名称"
                  v-model="select.dwdm" size="small">
                  <el-option :value="item.dwdm" v-for="item in orgUnitsList" :key="item.value" :label="item.dwmc">
                    {{item.dwmc}}
                  </el-option>
                </el-select>
                <button type="button" class="btn btn-info h30 flexList mr-2" @click="searchClick"><i
                    class="iconfont mr-2 icon-mb-search"></i>查询</button>
                <b-button @click="addItem" variant="outline-info" class="flexList mr-2 condition"><i
                    class="iconfont icon-plus-circle1 mr-2 font-size-20"></i>添加部门</b-button>
                <div v-if="hasName" class="w-20">{{jgmc}} <span class="m-1">/</span>{{dwmc}}</div>
                <el-button size="small" type="primary" plain class="flexList mr-2 h30 "
                  @click="$importModal().show({title: importTitle, module:module,success:uploadSuccess})"><i
                    class="iconfont icon-riLine-upload-2-line mr-2"></i>{{importTitle}}</el-button>
              </div>
              <div class="d-flex">
                <div class="border-blue export-tab"
                  @click="$exportModal().show({title: exportTitle, type: 'xlsx', module:module,condition:select})"><i
                    class="iconfont icon-antOutline-file-excel mr-2"></i>excel</div>
                <div class="border-blue export-tab"
                  @click="$exportModal().show({title: exportTitle, type:'dbf', module:module,condition:select})"><i
                    class="iconfont icon-data mr-2"></i>dbf</div>
              </div>
            </div>
            <div class="table-responsive border mt-3">
              <table class="table  light-table table-hover ">
                <thead class="thead-light">
                  <tr>
                    <th style="width:3%">序号</th>
                    <th style="width:8%">所属机构</th>
                    <th>所属单位</th>
                    <th style="width: 7%;">部门代码</th>
                    <th style="width:8%">部门名称</th>
                    <th style="width:6%">所属上级部门</th>
                    <th style="width:8%">是否启用</th>
                    <th style="width: 8%;">部门负责人</th>
                    <th style="width: 20%;">部门简介</th>
                    <th style="width: 6%;">修改人</th>
                    <th style="width: 10%;">修改时间</th>
                    <th style="width: 4%;">操作</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(obj,index) in tableList" :key="index">
                    <td>{{index+1+(pageData.pageNum-1)*pageData.pageSize}}</td>
                    <td> {{obj.jgmc}}</td>
                    <td>{{obj.dwmc}}</td>
                    <td>{{obj.bmdm}}</td>
                    <td><a href="/admin/basePosition" class="font-blue">{{obj.bmmc}}</a></td>
                    <td>{{obj.ssbmdm}}</td>
                    <td>
                      <b-form-checkbox v-model="obj.qyzt" switch class=" switch-check" @change="changeQyzt(obj)">
                      </b-form-checkbox>
                    </td>
                    <td>{{obj.fzrxm}}</td>
                    <td>{{obj.bmjj}}</td>
                    <td>{{obj.modifyUser}}</td>
                    <td>{{timeSlice(obj.modifyTime)}}</td>
                    <td class="tab-icon"><i class="iconfont icon-edit-two align-middle mr-1"
                        @click='editItem(obj)'></i><i class="iconfont icon-riLine-delete-bin-line align-middle mr-1"
                        @click="deleteItem(obj.sid,obj.bmmc)"></i></td>
                  </tr>

                </tbody>
              </table>

            </div>
            <div class="float-right d-flex ">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->
    <!-- 添加部门-->
    <b-modal id="addDepartment" v-model="showmodal" centered :title="this.dialogTitle" title-class="font-18"
      hide-footer>
      <div>
        <el-form ref="addForm" :model="addForm">
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>所属机构：</label>
              <div class="col-sm-10  p-0">
                <select class="w-100 form-control form-select" v-model="addForm.jgdm" @change="getjgmc">
                  <option value="" disabled>请选择机构</option>
                  <option :value="item.jgdm" v-for="item in orgList" :key="item.value" :label="item.jgmc">
                    {{item.jgmc}}
                  </option>
                </select>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>所属单位：</label>
              <div class="col-sm-10  p-0">
                <select class="w-100 form-control form-select" v-model="addForm.dwdm" @change="getdwmc">
                  <option value="" disabled>请选择单位</option>
                  <option :value="item.dwdm" v-for="item in orgUnitsList" :key="item.value" :label="item.dwmc">
                    {{item.dwmc}}
                  </option>
                </select>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>上级部门：</label>
              <div class="col-sm-10  p-0">
                <select name="" class="form-control form-select w-100" v-model="addForm.ssbmdm">
                  <option value="" disabled>请选择上级部门</option>
                  <option value="01">01</option>
                  <option value="02">02</option>
                  <option value="03">03</option>
                  <option value="04">04</option>
                </select>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>部门代码：</label>
              <div class=" col-sm-10 p-0 flexList">
                <input type="text" name="" id="" value="" maxlength="12" :disabled="disabled" v-model="addForm.bmdm"
                  placeholder="系统唯—" class="form-control w-100 h30 " />

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>部门名称：</label>
              <div class="col-sm-10  p-0">
                <input type="text" name="" id="" value="" maxlength="30" v-model="addForm.bmmc" placeholder="请输入部门名称"
                  class="form-control w-100 h30 " />
              </div>

            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>负责账号：</label>
              <div class="col-sm-10 p-0 flexList">
                <input type="text" name="" id="" value="" maxlength="50" v-model="addForm.fzrzh"
                  placeholder="部门负责人账号，可添加或从库选择" class="form-control w-100 h30 " />

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label class="w-15">负责人：</label>
              <div class="col-sm-10 p-0 flexList">
                <input type="text" name="" id="" value="" maxlength="100" v-model="addForm.fzrxm"
                  placeholder="部门负责人，可添加或从库选择" class="form-control w-100 h30 " />

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>部门简介：</label>
              <div class="col-sm-10 p-0 flexList">
                <textarea rows="3" cols="" class="form-control" maxlength="200" v-model="addForm.bmjj"
                  placeholder="多行输入"></textarea>

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3"><label class="mb-0">是否启用：</label>
              <div class="col-sm-10 p-0 flexList">
                <div class="form-check mr-2 w-15"><input type="radio" name="qyzt" id="exampleRadios1" value=true
                    v-model="addForm.qyzt" class="form-check-input"><label for="exampleRadios1"
                    class="form-check-label">启用
                  </label></div>
                <div class="form-check mr-2"><input type="radio" name="qyzt" id="exampleRadios1" value=false
                    v-model="addForm.qyzt" class="form-check-input"><label for="exampleRadio1"
                    class="form-check-label">不启用</label></div>

              </div>
            </div>
          </el-form-item>
        </el-form>

      </div>
      <div class=" mt-3  text-center"><button type="button" class="btn btn-info h30 w-md mr-3"
          @click="submit">确定</button>
        <button type="button" class="btn btn-secondary h30  w-md" @click="showmodal = false">取消</button>
      </div>



    </b-modal>
    <!-- 弹窗结束 -->
  </Layout>
</template>
